import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { Card } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { addTipsAmount } from "../../../store/actions/cart";
import AlFuttaimCustomTipsModal from "./AlFuttaimCustomTipsModal";
import {
  convertNumerals,
  convertStringNumerals,
} from "../../../constants/utils";

const useStyles = makeStyles(CartStyle);

export default function AlFuttaimTips(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { menuData, selected_language } = menuInfo;
  const { tipsAmount: cTipsAmount } = cartInfo;
  const {
    price_decimal_places,
    ui_strings,
    enable_arabic_numerals,
    menu_country,
  } = menuData;

  const { orderData } = props;
  const { total, additional_data, split_payments } = orderData;
  const [tip, setTip] = useState(additional_data?.tips_amount ? "custom" : 0);
  const [tipsAmount, setTipsAmount] = useState(cTipsAmount);
  const [showCustomTipsModal, setCustomTipsModal] = useState(false);
  const [orderTotal, setOrderTotal] = useState(Number(total));
  const [showTips, setShowTips] = useState(false);

  useEffect(() => {
    if (split_payments?.length > 0) {
      setShowTips(false);
    } else if (
      total &&
      additional_data?.tips_amount &&
      additional_data?.tips_amount !== "0.00"
    ) {
      setShowTips(false);
    } else if (total && !additional_data?.tips_amount) {
      setShowTips(true);
    }
  }, [total, additional_data?.tips_amount, split_payments]);

  useEffect(() => {
    if (additional_data?.tips_amount) {
      setOrderTotal(Number(total) - Number(additional_data?.tips_amount));
      setTip("custom");
      dispatch(addTipsAmount(additional_data?.tips_amount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additional_data, total]);

  useEffect(() => {
    if (tipsAmount !== "0.00") {
      setTip("custom");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (total) {
      setOrderTotal(Number(total));
    }
  }, [total]);

  const handleTip = (event, newTip) => {
    if (newTip !== null) {
      setTip(newTip);
      if (newTip !== "custom") {
        const tipsAmount = Number(orderTotal * (newTip / 100)).toFixed(
          price_decimal_places
        );
        setTipsAmount(tipsAmount);
        dispatch(addTipsAmount(tipsAmount));
      } else {
        setCustomTipsModal(true);
      }
    }
  };

  return showTips ? (
    <Card className={classes.card}>
      <div className={classes.cardContainer}>
        <div className={classes.cardTitle}>
          {ui_strings?.ui_str_add_tips_request ?? "Would you like to add tips?"}
        </div>
        <div className={classes.cardContent}>
          <ToggleButtonGroup
            value={tip}
            onChange={handleTip}
            aria-label="tips options"
            exclusive
            classes={{
              root: classes.tipsContainer,
              groupedHorizontal: classes.tipsButtonGrouped,
            }}
          >
            <ToggleButton
              value={0}
              classes={{ label: classes.tipsContent }}
              selected={tip === 0}
            >
              <div className={classes.tipsAmount}>
                {convertNumerals(
                  "0.00",
                  selected_language,
                  menu_country,
                  enable_arabic_numerals,
                  price_decimal_places
                )}
              </div>
              <div>
                {convertStringNumerals(
                  "0%",
                  selected_language,
                  enable_arabic_numerals
                )}
              </div>
            </ToggleButton>
            <ToggleButton
              value={5}
              classes={{ label: classes.tipsContent }}
              selected={tip === 5}
            >
              {orderTotal > 0 && (
                <div className={classes.tipsAmount}>
                  {convertNumerals(
                    (orderTotal * 0.05).toFixed(price_decimal_places),
                    selected_language,
                    menu_country,
                    enable_arabic_numerals,
                    price_decimal_places
                  )}
                </div>
              )}
              <div>
                {convertStringNumerals(
                  "5%",
                  selected_language,
                  enable_arabic_numerals
                )}
              </div>
            </ToggleButton>
            <ToggleButton
              value={10}
              classes={{ label: classes.tipsContent }}
              selected={tip === 10}
            >
              {orderTotal > 0 && (
                <div className={classes.tipsAmount}>
                  {convertNumerals(
                    (orderTotal * 0.1).toFixed(price_decimal_places),
                    selected_language,
                    menu_country,
                    enable_arabic_numerals,
                    price_decimal_places
                  )}
                </div>
              )}
              <div>
                {convertStringNumerals(
                  "10%",
                  selected_language,
                  enable_arabic_numerals
                )}
              </div>
            </ToggleButton>
            <ToggleButton
              value={15}
              classes={{ label: classes.tipsContent }}
              selected={tip === 15}
            >
              {orderTotal > 0 && (
                <div className={classes.tipsAmount}>
                  {convertNumerals(
                    (orderTotal * 0.15).toFixed(price_decimal_places),
                    selected_language,
                    menu_country,
                    enable_arabic_numerals,
                    price_decimal_places
                  )}
                </div>
              )}
              <div>
                {convertStringNumerals(
                  "15%",
                  selected_language,
                  enable_arabic_numerals
                )}
              </div>
            </ToggleButton>
            <ToggleButton
              value={"custom"}
              style={{ textTransform: "capitalize" }}
              selected={tip === "custom"}
              onClick={() => setCustomTipsModal(true)}
            >
              {ui_strings?.ui_str_tips_custom_amount ?? "Custom"}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <AlFuttaimCustomTipsModal
        handleOpen={showCustomTipsModal}
        handleClose={() => setCustomTipsModal(false)}
        tipsAmount={tipsAmount}
      />
    </Card>
  ) : null;
}
