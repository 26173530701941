import { DateTime } from "luxon";
import { hexToRgb } from "../assets/jss/material-kit-pro-react";
import {
  ar,
  de,
  enUS,
  es,
  fr,
  he,
  it,
  ja,
  ko,
  nl,
  ru,
  th,
  tr,
  zhHK,
} from "date-fns/locale";

export function flat(input, depth = 1, stack = []) {
  // eslint-disable-next-line
  for (let item of input) {
    if (item instanceof Array && depth > 0) {
      flat(item, depth - 1, stack);
    } else {
      stack.push(item);
    }
  }
  return stack;
}

export function fontName(name) {
  let fn;
  let fw;
  let ls;

  if (name) {
    let font = name.split("-");
    let weight = font.slice(-1)[0];

    if (weight === "Regular") {
      fw = "normal";
    } else {
      fw = "bold";
    }

    font.pop();
    fn = font.join(" ");
  }

  if (fn === "Oswald") {
    ls = "1px";
  } else {
    ls = "normal";
  }

  return { fontFamily: fn, fontWeight: fw, letterSpacing: ls };
}

export const localeMap = {
  en: enUS,
  ar: ar,
  de: de,
  es: es,
  fr: fr,
  he: he,
  it: it,
  ja: ja,
  kp: ko,
  nl: nl,
  ru: ru,
  th: th,
  tr: tr,
  zh: zhHK,
};

export function currency_symbol(code) {
  const currency_symbols = {
    AED: "AED ",
    BHD: "BHD ",
    EGP: "EGP ",
    EUR: "€ ",
    GBP: "£ ",
    INR: "₹ ",
    JOD: "JOD ",
    KES: "KSh ",
    KWD: "KWD ",
    LBP: "LBP ",
    NGN: "₦ ",
    OMR: "OMR ",
    PHP: "₱ ",
    QAR: "QR ",
    RON: "RON ",
    SAR: "SAR ",
    SGD: "S$ ",
    TZS: "TSh ",
    USD: "US$ ",
    YER: "YER ",
  };

  let symbol = "";
  if (currency_symbols[code] !== undefined) {
    symbol = currency_symbols[code];
  }

  return symbol;
}

function has_closing_rule_for_now(now, closing_rules, timezone = "Asia/Dubai") {
  const rules = closing_rules.filter((cr) => {
    return (
      DateTime.fromISO(cr.start).setZone(timezone) <= now &&
      DateTime.fromISO(cr.end).setZone(timezone) >= now
    );
  });

  return rules.length > 0;
}

export function is_open(
  opening_hours = [],
  closing_rules = [],
  timezone = "Asia/Dubai"
) {
  if (closing_rules.length > 0) {
    const now = DateTime.local();
    if (has_closing_rule_for_now(now, closing_rules, timezone)) {
      return false;
    }
  }

  if (opening_hours.length > 0) {
    const now = DateTime.now();
    const current_weekday = now.weekday;
    const hours = opening_hours.filter((oh) => oh.weekday === current_weekday);

    if (hours.length > 0) {
      let now_from = DateTime.now().setZone(timezone);
      let now_to = DateTime.now().setZone(timezone);
      // Check for opening times
      for (let i = 0; i < hours.length; i++) {
        const fh = hours[i].from_hour.split(":");
        const th = hours[i].to_hour.split(":");

        now_from = now_from.set({ hour: fh[0], minute: fh[1], second: fh[2] });
        now_to = now_to.set({ hour: th[0], minute: th[1], second: th[2] });

        // if to hour is 12:00am, then add 1 day to it
        if (th[0] === "00" && th[1] === "00" && th[2] === "00") {
          now_to = now_to.plus({ days: 1 });
        }

        if (now_from <= now && now_to > now) {
          return false;
        }
      }
    }
  }

  return true;
}

export function colorToRGBA(color, alpha = 1) {
  const rgba = /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/gm;
  const hex = /^#(?:[A-Fa-f0-9]{3}){1,2}$/gm;

  if (color.match(rgba)) {
    return color.replace(/[^,]+(?=\))/, alpha);
  } else if (color.match(hex)) {
    return "rgba(" + hexToRgb(color) + ", " + alpha + ")";
  } else {
    return color;
  }
}

export const isEmpty = (obj) => {
  for (const i in obj) return false;
  return true;
};

const numeralSystems = {
  ar: ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"], // Arabic
  // th: ["๐", "๑", "๒", "๓", "๔", "๕", "๖", "๗", "๘", "๙"], // Thai
  // zh: ["〇", "一", "二", "三", "四", "五", "六", "七", "八", "九"], // Chinese
};

export const convertNumerals = (
  number,
  selected_language,
  menu_country,
  enable_arabic_numerals,
  price_decimal_places
) => {
  const formatted = new Intl.NumberFormat(
    `${selected_language}-${menu_country}`,
    {
      useGrouping: true,
      minimumFractionDigits: price_decimal_places,
    }
  ).format(number);

  if (!enable_arabic_numerals) return formatted;

  const numerals = numeralSystems[selected_language] || null;
  if (!numerals) return formatted;

  return formatted
    .replace(/\d/g, (digit) => numerals[digit])
    .replace(".", "٫")
    .replace(",", "٬");
};

export const convertStringNumerals = (
  string,
  selected_language,
  enable_arabic_numerals
) => {
  if (!enable_arabic_numerals) return string;
  const numerals = numeralSystems[selected_language] || null;
  if (!numerals) return string;

  return string.replace(/\d/g, (digit) => numerals[digit]);
};
