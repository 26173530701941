import React, { forwardRef, useCallback, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Emenu/Header/Header.js";
import HomeStyle from "./HomeStyle";
import { useDispatch, useSelector } from "react-redux";
import { is_open } from "../../constants/utils";
import firebase from "../../constants/Firebase";
import MenuClosed from "../../components/Emenu/MenuClosed/MenuClosed";
import { Helmet } from "react-helmet";
import { addCart } from "../../store/actions/cart";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import VariationModal from "../../components/Emenu/Product/VariationModal";
import RenderSpecialCategory from "../../components/Emenu/Product/RenderSpecialCategory";
import RenderSubCategories from "../../components/Emenu/Product/RenderSubCategories";
import CartOrderType from "../../components/Emenu/Cart/CartOrderType";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from "react-player/lazy";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(HomeStyle);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function HomePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { menuData, isOpen, selected_language } = menuInfo;
  const {
    ui_strings,
    timezone,
    theme_data,
    home_header_text,
    home_footer_text,
    license,
    home_display_all_categories,
    home_view_format,
    addtocart_redirect,
    taxes,
    display_prices_with_tax,
    price_decimal_places,
    enable_display_order_type_home,
    categories: allCategories,
    al_futtaim_enabled,
    category_background,
    promotions,
    promotion_on_load,
    skip_onboarding_screen,
  } = menuData;

  const { orderLocation, orderLocationType, afActiveOrder } = cartInfo;

  // For promotion popup dialog
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [promotion_popup] = useState(
    promotions && promotion_on_load && skip_onboarding_screen
      ? promotions.find((promotion) => promotion.id === promotion_on_load)
      : null
  );

  const [promotionOpen, setOpen] = useState(!!promotion_popup);

  const handlePromotionClose = () => {
    setOpen(false);
  };

  const categories = allCategories.filter(
    (category) =>
      !category.parent && is_open(category.category_hours, [], timezone)
  );

  const currentCategory = {
    description: home_header_text,
    view_format: home_view_format,
    special_category_type: "all",
    special_category_display_categories: true,
  };

  const [showVariationModel, setShowVariationModal] = useState(false);

  const [modalProduct, setModalProduct] = useState({});

  const classes = useStyles();

  const getTaxes = useCallback(
    (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    },
    [display_prices_with_tax, taxes]
  );

  const handleCartButton = (product) => {
    let price = (parseFloat(product.price) + getTaxes(product.price)).toFixed(
      price_decimal_places
    );
    let discountedPrice =
      product.discount > 0
        ? parseFloat(product.price) -
          (parseFloat(product.price) * product.discount) / 100
        : 0;
    if (product.discount > 0) {
      discountedPrice = (
        parseFloat(discountedPrice) + getTaxes(discountedPrice)
      ).toFixed(price_decimal_places);
    }

    if (product.product_variations.length > 0) {
      setModalProduct(product);
      setShowVariationModal(true);
    } else {
      setModalProduct({});
      dispatch(
        addCart(
          uuidv4(),
          product.id,
          product.category,
          product.name,
          product.sku,
          product.images[0] ? product.images[0].image : null,
          discountedPrice > 0 ? discountedPrice : price,
          1,
          "",
          [],
          [],
          product.pos_available_qty
        )
      );
      if (addtocart_redirect === "cart") {
        history.push("/cart");
      } else if (addtocart_redirect === "back") {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.home_screen_bg_color;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor = theme_data?.home_screen_bg_color;
    }
    if (license) {
      firebase.analytics().logEvent("home_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [displayCartOrderType, setDisplayCartOrderType] = useState(false);

  useEffect(() => {
    if (afActiveOrder) {
      setDisplayCartOrderType(false);
    } else {
      if (al_futtaim_enabled) {
        if (orderLocation && orderLocationType === "Table") {
          setDisplayCartOrderType(true);
        } else {
          setDisplayCartOrderType(false);
        }
      } else {
        setDisplayCartOrderType(enable_display_order_type_home);
      }
    }
  }, [
    afActiveOrder,
    al_futtaim_enabled,
    enable_display_order_type_home,
    orderLocation,
    orderLocationType,
  ]);

  const bgStyle = {
    backgroundImage: `url(${category_background?.resized})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme_data?.home_screen_bg_color,
    height: "100vh",
    width: "inherit",
    maxWidth: "inherit",
    position: "fixed",
    zIndex: "0",
  };

  return (
    <div className={classes.mainContainer}>
      <Header
        brand={ui_strings?.ui_str_home ? ui_strings?.ui_str_home : "Home"}
      />
      <Helmet>
        <title>
          {menuData?.name
            ? `${
                ui_strings?.ui_str_home ? ui_strings?.ui_str_home : "Home"
              } | ${menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div
        style={bgStyle}
        className={classNames(classes.toolbar, classes.main)}
      />
      <div>
        <div className={classes.container}>
          {!isOpen && (
            <MenuClosed
              text={
                ui_strings?.ui_str_menu_closed
                  ? ui_strings?.ui_str_menu_closed
                  : "We are currently closed"
              }
            />
          )}
          {displayCartOrderType && <CartOrderType displayHome={true} />}

          {home_display_all_categories ? (
            <RenderSpecialCategory
              currentCategory={currentCategory}
              handleCartButton={handleCartButton}
              key={selected_language}
            />
          ) : (
            <RenderSubCategories
              currentCategory={currentCategory}
              subcatagories={categories}
              key={selected_language}
            />
          )}
          <VariationModal
            product={modalProduct}
            handleOpen={showVariationModel}
            handleClose={() => setShowVariationModal(false)}
          />
          <div
            className={classes.homeFooterText}
            dangerouslySetInnerHTML={{ __html: home_footer_text }}
          />
        </div>
      </div>
      {promotion_popup && (
        <Dialog
          fullScreen={fullScreen}
          open={promotionOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="variation-modal-title"
          aria-describedby="variation-modal-description"
          onClose={handlePromotionClose}
          onClick={handlePromotionClose}
          classes={{ paper: classes.modal }}
        >
          <DialogTitle
            disableTypography
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
          >
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handlePromotionClose}
              style={{
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
              paddingBottom: 20,
            }}
          >
            {promotion_popup.video ? (
              <ReactPlayer
                url={promotion_popup.video}
                playing
                loop
                playsinline
                muted
                controls={false}
                width={"100%"}
                height={fullScreen ? "100%" : "80vh"}
                config={{
                  file: {
                    attributes: {
                      preload: "auto",
                      disablePictureInPicture: true,
                      poster: promotion_popup.image?.product,
                    },
                  },
                }}
              />
            ) : (
              <img
                style={{
                  width: "100%",
                  height: fullScreen ? "100%" : "80vh",
                  objectFit: "contain",
                }}
                src={promotion_popup.image?.product}
                srcSet={`${promotion_popup.image?.product} 1x,
                    ${promotion_popup.image?.retina} 2x`}
                alt={"Promotion"}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
