import React from "react";
import { Button, SvgIcon } from "@material-ui/core";
import { ReactComponent as OrderPlaced } from "../../../assets/svg/order-placed.svg";
import { ReactComponent as OrderAccepted } from "../../../assets/svg/order-accepted.svg";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import { useSelector } from "react-redux";

export default function AlFuttaimOrderStatus(props) {
  const grayColor = "#BBBBBB";
  const orangeColor = "#FFA500";
  const greenColor = "#5CBF38";

  const cartInfo = useSelector((state) => state.cartInfo);
  const menuInfo = useSelector((state) => state.menuInfo);
  const { ui_strings } = menuInfo.menuData;
  const { afOrderAccepted } = cartInfo;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "70px",
              backgroundColor: "white",
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "12px",
                height: "12px",
                backgroundColor: !afOrderAccepted ? orangeColor : grayColor,
                borderRadius: "100%",
                left: "-2px",
              }}
            />
          </div>
          <div
            style={{
              width: "42px",
              height: "42px",
              padding: "10px",
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          >
            <SvgIcon component={OrderPlaced} viewBox="0 0 13.82 20" />
          </div>

          <div style={{ marginLeft: "4px" }}>
            <div
              style={{
                lineHeight: "14px",
                color: !afOrderAccepted ? orangeColor : "inherit",
                fontWeight: !afOrderAccepted ? "bold" : "normal",
              }}
            >
              {ui_strings?.ui_str_order_pending ?? "Order Pending"}
            </div>
            <div style={{ fontSize: "12px", lineHeight: "14px" }}>
              {ui_strings?.ui_str_order_pending_description ??
                "Awaiting updates on your order"}
            </div>
          </div>
        </div>
        <EmenuNavlink to={"/home"}>
          <Button
            variant={"contained"}
            size={"small"}
            style={{ backgroundColor: greenColor, color: "white" }}
          >
            {ui_strings?.ui_str_edit ?? "Edit"}
          </Button>
        </EmenuNavlink>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "4px",
            height: "70px",
            backgroundColor: "white",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "12px",
              height: "12px",
              backgroundColor: afOrderAccepted ? greenColor : grayColor,
              borderRadius: "100%",
              left: "-2px",
            }}
          />
        </div>
        <div
          style={{
            width: "42px",
            height: "42px",
            padding: "10px",
            backgroundColor: "white",
            borderRadius: "100%",
          }}
        >
          <SvgIcon component={OrderAccepted} viewBox="0 0 19.32 18" />
        </div>
        <div style={{ marginLeft: "4px" }}>
          <div
            style={{
              lineHeight: "14px",
              color: afOrderAccepted ? greenColor : "inherit",
              fontWeight: afOrderAccepted ? "bold" : "normal",
            }}
          >
            {ui_strings?.ui_str_order_accepted ?? "Order Accepted"}
          </div>
          <div style={{ fontSize: "12px", lineHeight: "14px" }}>
            {ui_strings?.ui_str_order_accepted_description ??
              "Your order has been accepted"}
          </div>
        </div>
      </div>
    </div>
  );
}
