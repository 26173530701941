import React from "react";
import Button from "../../CustomButtons/Button";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import { fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles(CartStyle);

export default function CartDeliveryButton(props) {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const { ui_strings, theme_data } = menuInfo.menuData;
  const {
    deliveryLocationConfirmed,
    deliveryFormattedAddress,
    enableWishlist,
    minOrderAmountError,
  } = cartInfo;

  const buttonStyle = {
    backgroundColor: theme_data?.cart_screen_submit_order_btn_color,
    color: theme_data?.cart_screen_submit_order_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_submit_order_btn_font_size),
    ...fontName(theme_data?.cart_screen_submit_order_btn_font_family),
  };

  return (
    !enableWishlist &&
    !minOrderAmountError && (
      <>
        {(!deliveryLocationConfirmed || !deliveryFormattedAddress) && (
          <div className={classes.checkoutButtonContainer}>
            <EmenuNavlink to={"/delivery-location"}>
              <Button
                fullWidth
                className={classes.menuButton}
                style={buttonStyle}
              >
                {ui_strings?.ui_str_set_delivery_location
                  ? ui_strings.ui_str_set_delivery_location
                  : "Set Delivery Location"}
              </Button>
            </EmenuNavlink>
          </div>
        )}
        {deliveryLocationConfirmed && deliveryFormattedAddress && (
          <div className={classes.selectedAddressContainer}>
            <RoomIcon
              style={{
                color: "#E24839",
              }}
            />
            <div className={classes.selectedAddress}>
              <div className={classes.selectedAddressHint}>
                {ui_strings?.ui_str_delivery_location ?? "Delivery Location"}
              </div>
              <div>{deliveryFormattedAddress}</div>
            </div>
            <EmenuNavlink to={"/delivery-location"}>
              <Button
                size={"sm"}
                style={{
                  marginLeft: 10,
                  backgroundColor: "#E24839",
                }}
              >
                {ui_strings?.ui_str_edit ?? "Edit"}
              </Button>
            </EmenuNavlink>
          </div>
        )}
      </>
    )
  );
}
