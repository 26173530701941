import React, { useEffect, useState } from "react";
import { makeStyles, rgbToHex } from "@material-ui/core/styles";
import Header from "components/Emenu/Header/Header.js";
import OrderStyle from "./OrderStyle";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";
import CartButton from "../../components/Emenu/Cart/CartButton";
import { fontName, isEmpty } from "../../constants/utils";
import axios from "axios";
import { APP_URL } from "../../constants/Api";
import { FormProvider, useForm } from "react-hook-form";
import FormItem from "../../components/Emenu/Form/FormItem";
import Button from "../../components/CustomButtons/Button";
import Loader from "react-loader-spinner";
import Lottie from "react-lottie-player";
import { replaceColor } from "lottie-colorify";
import emailConfirmation from "../../assets/lotties/16894-email-confirmation-sent.json";
import AlFuttaimOrderStatus from "../../components/Emenu/Cart/AlFuttaimOrderStatus";
import Pusher from "pusher-js";
import AlFuttaimCheckoutButton from "../../components/Emenu/Cart/AlFuttaimCheckoutButton";
import OrderTotals from "../../components/Emenu/Checkout/OrderTotals";
import {
  afSetOrderAccepted,
  orderClear,
  resetIikoActiveOrders,
} from "../../store/actions/cart";
import ReceiptButton from "../../components/Emenu/Checkout/ReceiptButton";

const useStyles = makeStyles(OrderStyle);

export default function OrderPage(props) {
  const { match, location } = props;
  const orderUUId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const { selected_language, menuData } = menuInfo;

  const {
    ui_strings,
    theme_data,
    license,
    forms,
    al_futtaim_enabled,
    name,
  } = menuData;

  const {
    orderLocation,
    afActiveOrder,
    afOrderAccepted,
    afCommonOrder,
  } = cartInfo;

  const [orderData, setOrderData] = useState(location?.state?.order || {});
  const [balance_due, setBalanceDue] = useState(orderData?.balance_due || 0);

  const [showThanks, setShowThanks] = useState(!!location?.state?.order);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.cart_screen_bg_color;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor = theme_data?.cart_screen_bg_color;
    }

    if (license) {
      firebase.analytics().logEvent("order_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if accepted or preparing, then mark as accepted
    if (["accepted", "preparing"].includes(orderData?.status)) {
      dispatch(afSetOrderAccepted(true));
    } else {
      dispatch(afSetOrderAccepted(false));
    }
    if (["fulfilled", "canceled", "rejected"].includes(orderData?.status)) {
      dispatch(resetIikoActiveOrders());
      dispatch(orderClear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.status]);

  const fetchOrderData = () => {
    axios
      .get(`${APP_URL}/${selected_language}/api/order/${orderUUId}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const order_table = parseInt(
            response.data?.customer_name.replace(/\D/g, "")
          );
          if (orderLocation && order_table !== orderLocation) {
            // If location is different, clear the order
            dispatch(resetIikoActiveOrders());
            dispatch(orderClear());
          } else {
            setOrderData(response.data);
            setShowThanks(false);
          }
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
        //console.log(error.config);
      });
  };

  useEffect(() => {
    if (selected_language && orderUUId) {
      fetchOrderData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUUId, selected_language]);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
      cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
      encrypted: true,
    });

    // Subscribe to the 'order_channel'
    const channel = pusher.subscribe(`${orderUUId}`);

    // Bind to the 'order_update' event within the channel
    channel.bind("order-update", (data) => {
      // console.log("Order updated", data);
      fetchOrderData();
    });

    // Unbind from the event and unsubscribe from the channel when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentForm =
    showThanks &&
    forms?.length > 0 &&
    forms.filter((form) => form.display_on_order_success).length > 0
      ? forms.filter((form) => form.display_on_order_success)[0]
      : null;

  const [loadingForm, setLoadingForm] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [currentFormData, setCurrentFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const methods = useForm({ mode: "onChange" });
  const { isValid, isSubmitting } = methods.formState;

  useEffect(() => {
    if (currentForm && selected_language) {
      setLoadingForm(true);
      axios
        .get(
          `${APP_URL}/${selected_language}/api/feedback/${currentForm.id}/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setCurrentFormData(response.data);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
  }, [currentForm, selected_language]);

  const formButtonStyle = {
    backgroundColor: theme_data?.feedback_submit_btn_color,
    color: theme_data?.feedback_submit_btn_text_color,
    fontSize: Number(theme_data?.feedback_submit_btn_font_size),
    ...fontName(theme_data?.feedback_submit_btn_font_family),
    marginTop: 40,
  };

  const isHex = (color) => {
    return /^#[0-9A-F]{6}$/i.test(color);
  };

  const onSubmit = (data) => {
    // Get Form Fields
    if (isValid && currentFormData?.id) {
      const submissionData = {};
      submissionData["feedback_id"] = currentFormData?.id;
      submissionData["submission"] = data;

      axios
        .post(
          `${APP_URL}/${selected_language}/api/submission/`,
          JSON.stringify(submissionData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 201) {
            setIsSubmitSuccessful(true);
            setSuccessMessage(response.data.message);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
  };

  const successTextStyle = {
    fontSize: Number(theme_data?.cart_screen_cart_success_font_size),
    color: theme_data?.cart_screen_cart_success_text_color,
    ...fontName(theme_data?.cart_screen_cart_success_font_family),
    textAlign: "center",
    marginBottom: 30,
  };

  const feedbackSuccessStyle = {
    color: theme_data?.feedback_success_text_color,
    fontSize: Number(theme_data?.feedback_success_font_size),
    ...fontName(theme_data?.feedback_success_font_family),
  };

  return (
    <div className={classes.mainContainer}>
      <Header brand={ui_strings?.ui_str_checkout_success ?? "Order Success"} />
      <Helmet>
        <title>
          {name
            ? `${
                ui_strings?.ui_str_checkout_success ?? "Order Success"
              } | ${name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          {!isEmpty(orderData) && (
            <div className={classes.orderReceipt}>
              {showThanks && (
                <div style={successTextStyle}>
                  {ui_strings?.ui_str_order_thanks ??
                    "Thank you for placing the order. Our team will get back to you shortly!"}
                </div>
              )}
              <OrderTotals
                orderData={orderData}
                balance_due={balance_due}
                setBalanceDue={setBalanceDue}
              />
            </div>
          )}
          <div className={classes.menuTotalsWave}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343 13.98">
              <path
                fill="#fff"
                d="M0 0v8.114a4 4 0 0 0 5.691 3.625l11.674-5.445a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l15.673 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l15.673 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.381 0l15.675 7.311a4 4 0 0 0 3.381 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l11.673 5.445A4 4 0 0 0 343 8.114V0Z"
                data-name="Path 54"
              />
            </svg>
          </div>
          {!isSubmitSuccessful &&
            showThanks &&
            loadingForm &&
            (!isEmpty(currentFormData) ? (
              <div className={classes.formContainer}>
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className={classes.form}
                  >
                    {currentFormData.form_data.map((item, index) => (
                      <FormItem item={item} key={index} />
                    ))}
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      fullWidth
                      style={formButtonStyle}
                      disabled={!isValid}
                    >
                      {isSubmitting
                        ? ui_strings?.ui_str_feedback_submit
                          ? ui_strings.ui_str_feedback_submitting
                          : "Submitting"
                        : ui_strings?.ui_str_feedback_submit
                        ? ui_strings.ui_str_feedback_submit
                        : "Submit"}
                    </Button>
                  </form>
                </FormProvider>
              </div>
            ) : (
              <div>
                <Loader
                  type="Oval"
                  height={50}
                  width={50}
                  className={classes.loader}
                />
              </div>
            ))}
          {isSubmitSuccessful && (
            <div className={classes.success}>
              <Lottie
                loop={false}
                speed={3}
                play={isSubmitSuccessful}
                animationData={replaceColor(
                  [204, 204, 204],
                  isHex(theme_data?.feedback_success_text_color)
                    ? theme_data.feedback_success_text_color
                    : rgbToHex(theme_data?.feedback_success_text_color).slice(
                        0,
                        -2
                      ),
                  emailConfirmation
                )}
                style={{ width: "50%", height: "50%" }}
              />
              <div className={classes.successMessage}>
                <div
                  style={feedbackSuccessStyle}
                  dangerouslySetInnerHTML={{ __html: successMessage }}
                ></div>
              </div>
            </div>
          )}

          {afCommonOrder && (
            <div
              style={{
                fontSize: 20,
                padding: 20,
                color: "red",
                textAlign: "center",
                width: "100%",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                borderRadius: 10,
                marginTop: 20,
              }}
            >
              Table is busy with POS order. Please proceed ordering with a
              waiter. Thanks
            </div>
          )}

          {!afCommonOrder &&
          al_futtaim_enabled &&
          !!afActiveOrder &&
          orderUUId === afActiveOrder &&
          orderData?.balance_due > 0 ? (
            <>
              {!["rejected", "canceled", "fulfilled"].includes(
                orderData?.status
              ) &&
                !orderData?.split_payments?.length > 0 && (
                  <AlFuttaimOrderStatus />
                )}
              {afOrderAccepted && <AlFuttaimCheckoutButton />}
            </>
          ) : (
            <>
              {al_futtaim_enabled && orderData?.balance_due <= 0 && (
                <ReceiptButton orderData={orderData} />
              )}
              <CartButton noText />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
