import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import CustomInput from "../../CustomInput/CustomInput";
import { css } from "@emotion/css";
import Button from "../../CustomButtons/Button";
import { fontName } from "../../../constants/utils";
import {
  addEmployeeDiscount,
  deleteEmployeeID,
} from "../../../store/actions/cart";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(CartStyle);

export default function AlFuttaimEmployeeDiscount(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const {
    employeeID: cEmployeeID,
    invalidEmployeeDiscount: cInvalidEmployeeDiscount,
    loadingEmployeeDiscount,
    enableWishlist,
  } = cartInfo;

  const { theme_data, ui_strings } = menuInfo.menuData;
  const [employeeId, setEmployeeId] = useState(cEmployeeID);
  const [invalidEmployeeDiscount, setInvalidEmployeeDiscount] = useState(
    cInvalidEmployeeDiscount
  );

  const couponButtonTextStyle = {
    backgroundColor: theme_data?.cart_screen_continue_shopping_btn_color,
    fontSize: Number(theme_data?.cart_screen_cart_item_delete_btn_font_size),
    color: theme_data?.cart_screen_continue_shopping_btn_text_color,
    ...fontName(theme_data?.cart_screen_cart_item_delete_btn_font_family),
  };

  const handleEmployeeId = () => {
    if (cEmployeeID) {
      dispatch(deleteEmployeeID());
    } else {
      dispatch(addEmployeeDiscount(employeeId));
    }
  };

  const handleEmployeeChange = (e) => {
    setInvalidEmployeeDiscount(false);
    setEmployeeId(e.target.value);
  };

  useEffect(() => {
    setEmployeeId(cEmployeeID);
  }, [cEmployeeID]);

  useEffect(() => {
    setInvalidEmployeeDiscount(cInvalidEmployeeDiscount);
  }, [cInvalidEmployeeDiscount]);

  return (
    !enableWishlist && (
      <Card className={classes.couponCard} style={{ marginBottom: "0px" }}>
        <div className={classes.couponContainer}>
          <div className={classes.couponGridItem}>
            {cEmployeeID ? (
              <div className={classes.couponCode}>{cEmployeeID}</div>
            ) : (
              <CustomInput
                id={"couponCode"}
                error={invalidEmployeeDiscount}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: employeeId,
                  onChange: handleEmployeeChange,
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            )}
            <Button
              simple
              style={couponButtonTextStyle}
              onClick={handleEmployeeId}
              disabled={loadingEmployeeDiscount || !employeeId}
            >
              {cEmployeeID
                ? ui_strings?.ui_str_remove_discount
                  ? ui_strings.ui_str_remove_discount
                  : "Remove Discount"
                : ui_strings?.ui_str_apply_discount
                ? ui_strings.ui_str_apply_discount
                : "Apply Discount"}
            </Button>
          </div>
        </div>
      </Card>
    )
  );
}
