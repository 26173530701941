import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import CardHeader from "../../Card/CardHeader";
import { Images } from "../../../constants";
import CardBody from "../../Card/CardBody";
import chefRecommendsIcon from "../../../assets/img/emenu/chef-recommends-icon.png";
import Badge from "../../Badge/Badge";
import ProductPricing from "../Pricing/ProductPricing";
import Card from "../../Card/Card";
import { useSelector } from "react-redux";
import { fontName } from "../../../constants/utils";

const useStyles = makeStyles(CartStyle);

export default function UpsellProducts(props) {
  const classes = useStyles();
  const { products } = props;

  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    theme_data,
    taxes,
    display_prices_with_tax,
    default_product_image,
    default_product_image_width,
    default_product_image_height,
    properties,
    display_properties_in_product,
    ui_strings,
  } = menuInfo.menuData;

  const defaultProductImage = {
    image: default_product_image?.resized,
    width: default_product_image_width,
    height: default_product_image_height,
  };

  const productNameStyle = {
    fontSize: Number(14),
    color: theme_data?.category_screen_product_title_text_color,
    ...fontName(theme_data?.category_screen_product_title_font_family),
  };

  const propertyBadgeStyle = {
    backgroundColor: theme_data?.product_screen_property_bg_color,
    color: theme_data?.product_screen_property_text_color,
    fontSize: Number(theme_data?.product_screen_property_font_size),
    ...fontName(theme_data?.product_screen_property_font_family),
  };

  const productPriceStyleZeroCondition =
    Number(theme_data?.category_screen_product_price_font_size) === 0
      ? { lineHeight: 0 }
      : null;

  const productPriceStyle = {
    fontSize: Number(12),
    color: theme_data?.category_screen_product_price_text_color,
    ...fontName(theme_data?.category_screen_product_price_font_family),
    ...productPriceStyleZeroCondition,
  };

  const upsellTitleStyle = {
    ...fontName(theme_data?.category_screen_product_title_font_family),
    fontWeight: "bold",
  };

  const getPrice = (product) => {
    return (
      parseFloat(product.price) + getTaxes(parseFloat(product.price))
    ).toFixed(3);
  };

  const getDiscountedPrice = (product) => {
    if (product.discount > 0) {
      const discountedPrice =
        parseFloat(product.price) -
        (parseFloat(product.price) * product.discount) / 100;
      return (
        parseFloat(discountedPrice) + getTaxes(parseFloat(discountedPrice))
      );
    } else {
      return 0;
    }
  };

  const getTaxes = (price) => {
    let taxAmount = 0;
    if (taxes.length > 0 && display_prices_with_tax) {
      for (let tax of taxes) {
        if (tax.type === "percentage") {
          taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
        }
      }
    }
    return taxAmount;
  };

  return (
    <div className={classes.upsellProductsContainer}>
      <div className={classes.upsellProductsTitle} style={upsellTitleStyle}>
        {ui_strings?.ui_str_would_you_like_to_add
          ? ui_strings.ui_str_would_you_like_to_add
          : "Would you like to add"}
        {":"}
      </div>
      <div className={classes.upsellProductsWrapper}>
        {products.map((product) => (
          <Card key={product.id} className={classes.upsellProductCard}>
            <EmenuNavlink
              disabled={product.disable_link || product.out_of_stock}
              external={product.external_link}
              to={{
                pathname: `/product/${product.id}`,
                categoryData: product,
              }}
            >
              <CardHeader
                image
                plain
                noShadow={false}
                children={
                  !product.hidden_image ? (
                    <img
                      src={
                        product.images[0]
                          ? product.images[0].image.product
                          : defaultProductImage.image
                          ? defaultProductImage.image
                          : Images.PlaceHolderProduct.image
                      }
                      style={{ height: "auto" }}
                      width={
                        product.images[0]
                          ? product.images[0].image_width
                          : defaultProductImage.width
                          ? defaultProductImage.width
                          : Images.PlaceHolderProduct.width
                      }
                      height={
                        product.images[0]
                          ? product.images[0].image_height
                          : defaultProductImage.height
                          ? defaultProductImage.height
                          : Images.PlaceHolderProduct.height
                      }
                      alt={product.name}
                    />
                  ) : null
                }
              />
              <CardBody
                className={classes.upsellProductTitle}
                children={
                  <div
                    style={{
                      padding: product.hidden_image ? "14px 0" : 0,
                    }}
                  >
                    {product.recommend && (
                      <div className={classes.upsellProductChefRecommends}>
                        <img src={chefRecommendsIcon} alt={"Chef Recommends"} />
                      </div>
                    )}

                    {product.out_of_stock && (
                      <div className={classes.upsellProductOutOfStock}>
                        {ui_strings?.ui_str_out_of_stock
                          ? ui_strings.ui_str_out_of_stock
                          : "Out of Stock"}
                      </div>
                    )}

                    <div style={productNameStyle}>{product.name}</div>

                    {product.properties.length > 0 &&
                      !display_properties_in_product && (
                        <div className={classes.upsellProductPropertyStyle}>
                          {product.properties.map((property) => {
                            const p = properties.find((x) => x.id === property);
                            return (
                              <div key={property}>
                                {p.image?.icon ? (
                                  <img
                                    className={classes.propertyIcon}
                                    src={p.image.icon}
                                    alt={p.name}
                                  />
                                ) : (
                                  <Badge style={propertyBadgeStyle}>
                                    {p.name}
                                  </Badge>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    <div style={productPriceStyle}>
                      {product.price > 0 && (
                        <ProductPricing
                          product={product}
                          price={getPrice(product)}
                          discountedPrice={getDiscountedPrice(product)}
                        />
                      )}
                    </div>
                  </div>
                }
              />
            </EmenuNavlink>
          </Card>
        ))}
      </div>
    </div>
  );
}
